import { render, staticRenderFns } from "./UserCommentsManagementPage.vue?vue&type=template&id=00a734ce&scoped=true&"
import script from "./UserCommentsManagementPage.vue?vue&type=script&lang=js&"
export * from "./UserCommentsManagementPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a734ce",
  null
  
)

export default component.exports