import { render, staticRenderFns } from "./CommentDetailItem.vue?vue&type=template&id=b31e4b28&scoped=true&"
import script from "./CommentDetailItem.vue?vue&type=script&lang=js&"
export * from "./CommentDetailItem.vue?vue&type=script&lang=js&"
import style0 from "./CommentDetailItem.vue?vue&type=style&index=0&id=b31e4b28&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.9.7@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b31e4b28",
  null
  
)

export default component.exports