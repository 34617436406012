<template>
  <div class="commentItem-box">
    <div class="commentTop-title" @click="onArticleClick">
      {{ info.articleTitle }}
    </div>
    <div class="commentMiddle-box">
      <div>
        <img class="left-headPortrait" :src="info.avatar" />
        <span class="userNickname">{{ info.userNickName }}</span>
      </div>
      <span class="right-del" @click="onDel">删除</span>
    </div>
    <div class="commentContent-box">
      <span class="comment-content">{{ info.content }}</span
      ><br />
      <span class="createTime">{{ info.createTime }}</span>
    </div>
    <div class="commentReply-box" v-if="show">
      <span class="spanFont">作者回复:</span><br />
      <div
        :class="showTotal ? 'shrink-content' : 'whole-content'">
        {{ info.reply }}
      </div>
      <div class="more" @click="showTotalIntro" v-if="showExchangeButton">
        <p>{{ exchangeButton ? "展开" : "收起" }}</p>
      </div>
    </div>
    <van-divider />
    <del-dialog
      :isShow="isShow"
      @onBack="isShow = false"
      @onDelete="onDelete">
    </del-dialog>
  </div>
</template>
<script>
import { Divider } from "vant";
import to from "@/utils/to";
import { deleteCommentInfo } from "@/api/mine";
import DelDialog from "./DelDialog";
export default {
  components: {
    [Divider.name]: Divider,
    DelDialog,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      isRouterAlive: true,
      show: false,
      isShow: false,
      showTotal: true,
      // 显示展开还是收起
      exchangeButton: true,
      // 是否显示展开收起按钮
      showExchangeButton: true,
    };
  },
  mounted() {
    if (this.info.reply != null) {
      this.show = true;
    } else {
      this.show = false;
    }
  },
  methods: {
    onArticleClick() {
      const articleId = this.info.articleId
      if (articleId) {
        this.$router.push({
        name: "Article",
        query: { id: articleId },
          });
      } else {
        this.$toast('该文章已被删除')
      }
    },
    onDel() {
      this.isShow = true;
    },
    onDelete() {
      this.deleteCommentInfo();
    },
    showTotalIntro() {
      this.showTotal = !this.showTotal;
      this.exchangeButton = !this.exchangeButton;
    },
    async deleteCommentInfo() {
      let [err] = await to(deleteCommentInfo(this.info.id));
      if (err) {
        return;
      }

      this.isShow = false;
      this.$emit('del-success');
    }
  
  },
};
</script>
<style scoped>
.commentItem-box {
  margin: 20px 16px 20px 16px;
}

.commentTop-title {
  font-size: 16px;
  font-weight: bold;
  line-height: 22px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  margin-bottom: 12px;
}

.commentMiddle-box {
  display: flex;
  justify-content: space-between;
}

.left-headPortrait {
  width: 30px;
  height: 30px;
  vertical-align: middle;
  border-radius: 50%;
}

.userNickname {
  font-size: 14px;
  line-height: 20px;
  color: #999999;
  margin-left: 8px;
}

.right-del {
  font-size: 14px;
  line-height: 20px;
  color: #57738f;
  margin-top: 0px;
}

.commentContent-box {
  margin-left: 36px;
  width: 305px;
  margin-right: 0px;
}

.comment-content {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
}

.createTime {
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  margin-left: 0px;
}

.commentReply-box {
  width: 305px;
  margin-left: 36px;
}

.spanFont {
  font-size: 14px;
  color: #999999;
}

.shrink-content {
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.whole-content {
   font-size: 16px;
  color: #333333;
  line-height: 22px;
  width: 300px;
  font-weight: normal;
  word-wrap: break-word;
}

.more {
  display: block;
  z-index: 11;
  float: right;
 }

p {
  font-size: 14px;
  margin: 0;
  color: #57738f;
}
</style>