<template>
  <div>
    <van-list 
      v-model="loading"
      :error.sync="listError"
      error-text="请求失败，点击重新加载"
      finished-text="到底了哦~"
      :finished="finished" @load="getList">

      <comment-detail-item
         v-for="(item, idx) in dataList"
          :key="idx" :info="item"
          @del-success="refreshList">
      </comment-detail-item>
    </van-list>
  </div>
</template>

<script>
import to from "@/utils/to";
import { getMineComments } from "@/api/mine";
import CommentDetailItem from "@/views/album/CommentDetailItem";
import { List } from "vant";
export default {
  components: {
    CommentDetailItem,
    [List.name]: List,
  },
  data() {
    return {
      loading: false,
      listError: false,
      finished: false,

      dataList: [],
      pageNum: 1,
    }
  },
  methods: {
    refreshList() {
      this.pageNum = 1
      this.getList()
    },
    async getList() {
      let [err, res] = await to(getMineComments(this.pageNum, 15));

      if (err) {
        this.listError = true;
        return;
      }

      if (res.rows.length === 0) {
        this.finished = true
        return;
      }

      console.log(this.pageNum)
      if (this.pageNum == 1) {
        this.dataList = res.rows;
      } else {
        this.dataList = this.dataList.concat(res.rows);
      }

      this.loading = false;
      this.pageNum = res.pageNum + 1;
    }
  }
};
</script>

<style scoped>

</style>