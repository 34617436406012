<template>
  <van-overlay :show="isShow">
    <div class="del-box">
      <div class="tips-box">确定要删除本条评论？</div>
      <div class="button-box">
        <div class="btn-cancel" @click="$emit('onBack')">取消</div>
        <div class="btn-del" @click="$emit('onDelete')">删除</div>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay, Divider } from "vant";
export default {
  props: ['isShow'],
  components: {
    [Divider.name]: Divider,
    [Overlay.name]: Overlay,
  },
}
</script>

<style scoped>
.del-box {
  position: relative;
  top: 40%;
  width: 80%;
  margin: auto;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
}

.tips-box {
  height: 131px;
  line-height: 131px;
  margin: 12px 12px 0 0;
  font-size: 16px;
  color: #333333;
  text-align: center;
}

.button-box {
  display: flex;
  padding: auto;
  align-items: center;
  text-align: center;
  height: 49px;
  line-height: 50px;
}

.btn-cancel {
  flex: 1;
  border-top: 1px solid #e2dddd;
  color: #999999;
}

.btn-del {
  flex: 1;
  height: 100%;
  border-top: 1px solid #e2dddd;
  color: #333333;
  background: #FFC83E;
}
</style>